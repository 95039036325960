<template>
  <layout-auth-form
    :loading="pending"
    @submit.prevent="restorePasswordUser"
  >
    <auth-input
      v-model="email"
      placeholder="E-mail"
      :error="isError"
      :error-message="restorePassStatus"
      @input="clearAllStatusMessages"
    />
    <auth-button :disabled="isError" type="submit" filled>
      {{ $t("RestorePassword.sendNewPass") }}
    </auth-button>
    <auth-button @click="$router.push('login')">
      {{ $t("RestorePassword.cancel") }}
    </auth-button>
    <template v-if="!!authDeclinedError" #error>
      <div v-html="authDeclinedError" />
    </template>
  </layout-auth-form>
</template>

<script>
import { mapActions } from "vuex";
import { auth } from "@/store/modules/store.namespaces";
import { RESTORE_PASSWORD } from "@/store/modules/auth/action-types";
import AuthButton from "@/components/common/auth/AuthButton";
import LayoutAuthForm from "@/components/layouts/LayoutAuthForm";
import AuthInput from "@/components/common/auth/AuthInput";
/**
 * Страница востановления пароля
 *
 * @vue-data {Boolean} pending - Тип иконки regular
 * @vue-data {String} authDeclinedError - Ошибка с сервера после отправки данных
 * @vue-data {String} restorePassStatus - Ошибка при вводе данных
 * @vue-data {String} email - Email используется для востановления пароля
 * @vue-computed {Boolean} isError - размер иконки 12px
 */
export default {
  name: "ViewAuthRestorePassword",
  components: {
    AuthInput,
    LayoutAuthForm,
    AuthButton,
  },
  data() {
    return {
      pending: false,
      authDeclinedError: "",
      restorePassStatus: "",
      email: "",
    };
  },
  computed: {
    isError() {
      return !!this.authDeclinedError || !!this.restorePassStatus;
    },
  },
  methods: {
    ...mapActions(auth, {
      restorePassword: RESTORE_PASSWORD,
    }),
    /**
     * Востановление пароля по Email обращение к серверу
     * @function
     */
    async restorePasswordUser() {
      if (this.isError) return;
      if (!this.email) {
        this.restorePassStatus = this.$t("Login.emptyValue");
        return;
      }
      try {
        this.pending = true;
        const res = await this.restorePassword({ login: this.email });
        if (res) {
          this.authDeclinedError = res;
        }
      } catch (err) {
        console.error(err);
        this.authDeclinedError = this.$t("Login.authDeclinedError");
      } finally {
        this.pending = false;
      }
    },
    /**
     * Сброс ошибок
     * @function
     */
    clearAllStatusMessages() {
      this.restorePassStatus = "";
      this.authDeclinedError = "";
    },
  },
};
</script>

<style scoped lang="less">
@ws-grey: #414141;
@ws-fs1: 16px;
@ws-lh1: normal;

.login {
  position: relative;
  margin: 0 auto;
  background-repeat: no-repeat;
  min-height: 100%;

  .login-loader {
    margin-top: 15px;
  }
  .main-login-btn {
    margin-bottom: 15px;
  }
  .form-title.select-lang-form {
    text-align: center;
    padding-top: 5px;
    margin-bottom: 20px;
  }
  input[type="password"],
  input[type="text"],
  input[type="email"] {
    border: none;
    width: 100%;
    padding: 10px 2px;
    border-bottom: 1px solid var(--base-text-color);
    font-size: @ws-fs1;
    line-height: @ws-lh1;
    background: none;
    color: inherit;
  }
  button.button.big {
    margin-top: 15px;
    width: 100%;
    max-width: 100%;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: var(--base-color);
    a {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  a.button.bottom {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: var(--base-color);
  }
}
.login {
  form {
    width: 310px;
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--login-form-color);
    border-radius: 5px;

    @media (max-width: 768px) {
      margin: 20px 5px;
    }
  }
}
.enterRoom {
  input[type="text"] {
    border: none;
    width: 100%;
    padding: 10px 2px;
    border-bottom: 1px solid @ws-grey;
    font-size: @ws-fs1;
    line-height: @ws-lh1;
    background: none;
    color: inherit;
  }
}
</style>
